import * as React from "react";
import {useEffect, useState} from "react";
import {blurhash} from "../../Service/Blurhash";

export const BlurhashImage = (props) => {
    const [blrhsh, setBlrhsh] = useState(``);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (!props.blurhash || !props.blurhash.hash || props.blurhash.hash.length === 0) {
            return
        }
        const im = blurhash.decode(
            props.blurhash.hash,
            Math.round(props.blurhash.width / 40) * 4,
            Math.round(props.blurhash.height / 40) * 4
        );
        blurhash.getImageDataAsImage(im, Math.round(props.blurhash.width / 40) * 4, Math.round(props.blurhash.height / 40) * 4, (e, im) => {
            setBlrhsh(im.src)
        })
    }, [props.blurhash, props.image]);

    return (
        <div className={`blurhash-image-container ${hide ? `blurhash-image-container--hide` : ``}`}>
            <img
                alt=""
                className={`blurhash-real-image ${props.className}`}
                src={props.image}
                height={props.blurhash?.height}
                width={props.blurhash?.width}
                onLoad={() => setHide(true)}
            />
            { blrhsh !== `` ? (
                <img alt="" className="blurhash" src={ blrhsh } />
            ) : null }
        </div>
    )
}